<template>
  <div class="content" :style="{ backgroundColor: background ? '' : 'unset' }">
    <div class="center fs-18 fw-600 color-606266 color-606266 mb-10">
      易理货个人信息保护政策
    </div>
    <p>
      为切实保护用户隐私权，优化用户体验， 青岛巨商汇网络科技有限公司（“易理货”或“我们”）根据现行法规及政策，制定本个人信息保护政策。我们了解个人信息对客户的重要性，我们力求明确说明我们获取、管理及保护用户个人信息的政策及措施。
    </p>
    <p>本个人信息保护政策将帮助您了解以下内容：</p>
    <p>一、我们会收集哪些信息（无论其是否为个人信息）</p>
    <p>二、我们如何使用信息</p>
    <p>三、我们如何共享信息</p>
    <p>四、我们如何转让信息</p>
    <p>五、我们如何公开披露信息</p>
    <p>六、一般储存期限</p>
    <p>七、我们如何确保您的信息安全</p>
    <p>八、您能做什么</p>
    <p>九、我们如何处理未成年人的个人信息</p>
    <p>十、您的个人信息的存储地点</p>
    <p>十一、本个人信息保护政策不适用的范围</p>
    <p>十二、本个人信息保护政策如何更新</p>
    <p>十三、联系我们</p>
    <p>
      本个人信息保护政策适用于引用或链接本个人信息保护政策的与易理货服务有关的网站、APP和（或）小程序等用户端（统称为我们的“服务”），即易理货。
    </p>
    <p class="mt-20">
      易理货深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最少够用原则、确保安全原则、主体参与原则、公开透明原则等。同时，易理货承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
    </p>
    <p class="fw-600 color-606266 color-606266">
      请务必仔细阅读本个人信息保护政策，特别是免除/限制责任的条款（以粗体下划线标识）,因为只要您使用我们的服务，就表示您同意个人信息保护政策及补充条文中所描述的做法。如果您不同意本个人信息保护政策中所描述的做法，您将无法使用我们的服务。
    </p>
    <p class="mt-20">
      一、我们会收集哪些信息（无论其是否为个人信息）
    </p>
    <p>
      为了向您提供我们的服务，我们会要求您提供向您提供服务所必需的个人信息。<span class="fw-600 color-606266">如果您不提供必要的个人信息，我们可能无法向您提供我们的产品或服务。</span> “个人信息”指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。
    </p>
    <p>
      1.您直接提供的信息：某些服务会要求您直接向我们提供信息，如用户注册、购买、送货服务。
    </p>
    <p>
      (1)用户注册：当您注册为我们的用户时，您需要提供您的姓名和联系方式。
    </p>
    <p>
      (2)下单：当您下单时，除上述已提供的信息外您还需要提供您的收货地址及收货人的姓名、联系方式。
    </p>
    <p>
      (3)开票：当您申请开票时除上述信息外，您还需要提供您的开票信息、邮箱地址。
    </p>
    <p class="fw-600 color-606266">
      个人敏感信息特别提醒：某些特殊的个人信息可能被认为是个人敏感信息，例如您的个人身份信息、账户信息、联系方式。在提供此类个人敏感信息前，您需要谨慎考虑，确保准确。易理货在此征得您的同意，您同意这些个人敏感信息将按照本个人信息保护政策所阐明的目的和方式来进行处理。如果您不提供这些信息或提供的信息有误，可能导致我们无法提供服务或影响我们提供服务的准确性。
    </p>
    <p>
      2.来自设备的信息：我们还可能通过您设备中的软件以及其他方式来收集您使用我们的服务的相关信息，如设备信息、日志信息、位置信息。
    </p>
    <p>
      (1)设备信息：您下载或使用易理货服务时，易理货可能会读取与您移动设备相关的信息，包括硬件型号、IMEI号码和其他唯一设备标识符、MAC地址、IP地址、操作系统版本以及用于访问服务的设备的设置。
    </p>
    <p>
      (2)日志信息：在您使用易理货服务，易理货自动接收并记录的您的浏览器和计算机上的信息，包括(a)您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录数据；(b)您物联网设备的日志信息。
    </p>
    <p>
      (3)位置信息（仅适用于特定服务/功能）：您下载或使用APP，易理货可能会读取与您位置相关的各类信息，如设备的GPS信号或附近的WiFi接入点和手机信号发射塔的相关信息、国家代码、城市代码、移动网络代码、小区标识、经纬度信息和语言设置，这些信息可能会在您使用以上服务时传送给我们。
    </p>
    <p>
      3.第三方来源信息：我们可能会从第三方合作伙伴获得您的信息，例如我们的经销商、您选择连接的第三方社交网络服务。
    </p>
    <p>
      4.我们收集的其它信息：除上述信息外，我们也可能收集其他信息，例如，您参与问卷调查时向我们发送的问卷答复信息、参加用户体验计划以及您与我们以及您与易理货合作伙伴之间互动时我们收集的信息。我们会在收集时向您说明具体收集的内容和具体的收集方式，征得您的同意。当我们要将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
    </p>
    <p class="fw-600 color-606266">
      5.您可以选择不提供某些类型的信息（例如，位置信息），但这样可能会影响您使用某些服务。
    </p>
    <p>
      我们会集成高德地图定位SDK，用于实现获取用户当前位置等功能。该SDK主体为：高德软件有限公司；收集的个人信息包含：位置信息（经纬度、精确位置、粗略位置）【通过IP地址、GNSS信息、WiFi状态、WiFi参数、WiFi列表、基站信息、信号强度的信息、蓝牙信息、传感器信息（矢量、加速度、压力）、设备信号强度信息获取】、设备标识信息（IMEI、IDFA、IDFV、AndroidID、MEID、MAC地址、OAID、IMSI、硬件序列号）、当前应用信息（应用名、应用版本号）、设备参数及系统信息（系统属性、设备型号、操作系统）。了解此情形中目前涉及的公司、组织和个人，请点击此处。
      <span @click="toLink('https://lbs.amap.com/pages/privacy/')" class="cursor-pointer">【https://lbs.amap.com/pages/privacy/】</span>
    </p>
    <p>
      6.您也可以通过搜索来精准地找到您所需要的商品或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品。请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；
    </p>
    <p/>
    <p>
      二、我们如何使用信息
    </p>
    <p>
      <span class="fw-600 color-606266">收集个人信息的目的在于向您提供产品和/或服务，并且保证我们遵守适用法律。</span> 我们可能将所收集的个人信息用于以下用途和目的：
    </p>
    <p>
      1.创建您的易理货账号。将通过网站或移动设备创建易理货账号时收集的个人信息用于建立用户的个人易理货账户和资料页。
    </p>
    <p>
      2.提供基于位置的服务。在使用某些易理货服务时，我们或第三方服务供应商可能会使用位置信息以为您提供正确版本的服务，并且提供准确的位置，以使您获得尽可能好的用户体验，例如通过读取您的位置了解与您最近的仓库并预估送货时间。您可以随时进入设备设置或停止使用这一应用程序来关闭这一功能。
    </p>
    <p>
      3.根据您的信息作出自动决策行为。在某些业务场景中，我们可能仅依据信息系统、算法在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将提供适当的救济方式。法律法规另有规定的除外。
    </p>
    <p>
      4.收集用户反馈。您选择提供的反馈对帮助易理货改进我们的服务而言极为珍贵。为了跟踪您选择提供的反馈，易理货可能会使用您所提供的个人信息与您联系，并保留记录
    </p>
    <p>
      5.发送通知。我们可能会不时使用您的个人信息来发送重要通知，例如有关购买物的物流信息和我们的条款、条件和政策变更。
    </p>
    <p>
      6.支付功能 在您下单后，您可以选择微信或者其他支持的支付机构所提供的支付服务。支付功能本身并不收集您的个人信息，但我们需要将您的订单号与交易金额信息与这些支付机构共享以实现其确认您的支付指令并完成支付。
    </p>
    <p>
      <span class="fw-600 color-606266">7.直接推广。</span> 我们可能会使用您的电话号码、易理货账号向您提供与易理货及其合作伙伴的产品和服务相关的推广资料。为了提供更好的用户体验，我们会根据您的使用历史、网站浏览历史、地理位置信息推荐上述产品、服务和活动。如果您不再希望接收某些类别的电子邮件或营销短信，您可以按照提示来取消订阅。未经您另外同意，我们不会将您的个人信息传送给我们的商业伙伴用于直接推广。
    </p>
    <p class="mt-20">
      8.激活和控制、使用家电设备。您控制您的部分海尔设备需要完成设备的配网、绑定流程。完成这些流程后，您即可通过易理货小程序远程控制或局域网内控制。部分家电设备需要激活后方可使用。激活过程需要使用相关的设备信息，包括：MAC地址、机器编码、TYPEID、WIFI密码。若激活失败，则我们可能读取相关的设备信息和日志信息用于评估激活失败的原因。
    </p>
    <p>
      9.提供其他易理货服务。
    </p>
    <p>
      <span class="fw-600 color-606266">请注意，以上的使用方式涵盖了易理货使用个人信息的主要方式。易理货可能时不时地通过更新小程序版本或设备固件方式，为用户提供更加多样化的服务。</span> 当我们要将信息用于本政策未载明的其他用途时，或要将基于特定目的收集而来的信息用于其他目的时，会以确认协议、具体场景下的确认行为、更新本隐私权协议的形式再次征求您的同意。
    </p>
    <p>
      10. 在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。
    </p>
    <p>
      三、我们如何共享信息
    </p>
    <p>
      <span class="fw-600 color-606266">未经您的同意，我们将不会向易理货以外的第三方共享您的信息。</span> 但是，我们可能和下列第三方共享您的信息：
    </p>
    <p>
      <span class="fw-600 color-606266">1.易理货关联公司。为便于我们基于易理货平台统一账号系统向您提供服务，推荐您可能感兴趣的信息或保护易理货或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会在易理货家电的关联公司之间共享。</span> 我们只会共享必要的个人信息（如为便于您通过使用【易理货账号】来使用我们关联公司产品或服务，我们会向易理货家电关联公司共享您必要的账户信息），如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意[这个实际能做到吗]。“关联公司”指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。
    </p>
    <p>
      <span class="fw-600 color-606266">2.授权合作伙伴。</span> 我们可能与可信的授权合作伙伴共享您的信息。我们可能会向授权合作伙伴共享您的账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。目前，我们的授权合作伙伴包括以下类型：
    </p>
    <p>
      (1)商品或技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。
    </p>
    <p>
      (2)广告、分析服务类的授权合作伙伴。
    </p>
    <p>
      (3)供应商、服务提供商和其他合作伙伴。我们的供应商和服务提供商包括提供技术服务、电信服务、客户服务、风控服务、审计服务、法律服务的机构、金融机构、电子商务公司及平台、线下卖场。
    </p>
    <p>
      对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，并会以适当的方式要求他们按照我们的说明、本个人信息保护政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用加密技术，从而更好地保护用户数据。一经发现其违反协议约定将会采取有效措施乃至终止合作。
    </p>
    <p>
      遵守法律法规强制性要求、法院命令或政府主管部门要求、维护社会公共利益，或保护我们的客户、易理货或易理货员工的人身和财产安全或合法权益所合理必需的用途。
    </p>
    <p>
      (4)如果您是食联贸第三方门店的店员，您的员工工号，姓名，手机号，岗位编码，产业名称，性别，生日会被提供给第三方门店的注册者。
    </p>
    <p>
      (5)如果您分享产品后获得佣金。为提取佣金，您需要注册xx平台，并提供姓名，电话，身份证，银行账号信息授权用于签订劳务电子合约，并由xx平台为您支付佣金。由于审计的需求，这些信息不能在您申请注销后立即删除，需要遵守国家相关财务等法律方面的规定。
    </p>
    <p>
      3.单独获得您同意的其他方。除本个人信息保护政策中描述的共享情况之外，我们也可能在您同意共享或提出共享请求时与第三方共享您的信息。
    </p>
    <p>
      四、我们如何转让信息
    </p>
    <p>
      我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：
    </p>
    <p>
      1.获得您的明确同意后；
    </p>
    <p>
      2.在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。
    </p>
    <p>
      五、我们如何公开披露信息
    </p>
    <p>
      我们仅会在以下情况下，公开披露您的个人信息：
    </p>
    <p>
      1.获得您明确同意后；
    </p>
    <p>
      2.基于法律的披露：基于法律法规强制性要求、法院命令或政府主管要求的情况下，我们会公开披露您的个人信息。
    </p>
    <p>
      六、一般储存期限
    </p>
    <p>
      <span class="fw-600 color-606266">除非法律另有明文规定或经您允许，我们只会在本个人信息保护政策所述目的所需的期限内保留您的个人信息。为了保持服务的持续性和用户体验的一致性，个人信息通常不会在单项服务完成后立即删除。</span> 例如用户历史订单中的送货地址，为了使用户在下次购物时更快捷地选择送货地址，易理货会保存该地址，直至用户在自己的用户设置中选择删除。
    </p>
    <p>
      七、我们如何确保您的信息安全
    </p>
    <p>
      1.我们已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。例如，我们会部署访问控制机制，确保只有授权人员才可访问个人信息；我们会举办安全和个人信息保护培训课程，加强员工对于保护个人信息重要性的认识。为保障您的信息安全，我们努力采取各种合理的物理、电子和管理方面的安全措施来保护您的信息，使您的信息不会被泄漏、毁损或者丢失，包括但不限于SSL、信息加密存储、数据中心的访问控制。我们对可能接触到您的信息的员工或外包人员也采取了严格管理，包括但不限于根据岗位的不同采取不同的权限控制，与他们签署保密协议，监控他们的操作情况措施。易理货会按现有技术提供相应的安全措施来保护您的信息，提供合理的安全保障，易理货将尽力做到使您的信息不被泄漏、毁损或丢失。
    </p>
    <p>
      <span class="fw-600 color-606266">2.互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。</span> 如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。
    </p>
    <p>
      3.在发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况告知您。
    </p>
    <p class="fw-600 color-606266">
      4.您的账户均有安全保护功能，请妥善保管您的账户及密码信息。同时，为了保障您的信息安全，请在向第三方永久性售出或转让您的设备使用权之前，确保退出您的账户并删除配对小程序，以确保您的设备不再（重新）匹配到您的账户。如果您售出的是设备（例如家用电器），则应重置设备并恢复出厂设置。这样，您的设备与账户之间将不再关联。如果您购买了二手的设备，请检查小程序中或设备商的账户设置以确保没有未知用户和您的家用电器相关联。如果还有任何疑问，您可以选择恢复出厂设置。易理货将通过备份、对用户密码进行加密安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您理解在信息网络上不存在“完美的安全措施”。
    </p>
    <p>
      <span class="fw-600 color-606266">5.易理货不对因您未能保持个人信息的私密性而导致第三方访问您的个人信息进而造成的安全疏漏承担责任。</span> 尽管有上述规定，如果发生互联网其他任何用户未经授权使用您账户的情况或其他任何安全漏洞，您应当立即通知我们。您的协助将有助于我们保护您个人信息的私密性。
    </p>
    <p>
      八、您能做什么
    </p>
    <p>
      1.访问您的个人信息
    </p>
    <p>
      您有权访问您的个人信息。如果您想行使数据访问权，可以通过以下方式自行访问：
    </p>
    <p>
      (1)账户信息：如果您希望访问或编辑您的账户中的个人资料信息和支付信息、更改您的密码、添加安全信息或关闭您的账户，您可以登录您的账户后通过访问设置页面执行此类操作。
    </p>
    <p>
      (2)其他个人信息：对于您在使用我们的产品或服务过程中产生的其他个人信息，只要我们不需要过多投入，我们会向您提供，请通过第十四节列明的联系方式与我们取得联系。
    </p>
    <p>
      2.更正您的个人信息
    </p>
    <p>
      当您发现我们处理的关于您的个人信息有错误时，您有权自行或要求我们做出更正。对于账户信息，您可以登录您的账户后通过访问设置页面执行此类操作。对于其他个人信息，请通过第十四节列明的联系方式与我们取得联系。
    </p>
    <p>
      3.删除您的个人信息
    </p>
    <p>
      在以下情形中，您可以向我们提出删除个人信息的请求：
    </p>
    <p>
      (1)如果我们处理个人信息的行为违反法律法规；
    </p>
    <p>
      (2)如果我们收集、使用您的个人信息，却未征得您的同意；
    </p>
    <p>
      (3)如果我们处理个人信息的行为违反了与您的约定；
    </p>
    <p>
      (4)如果您注销了账号；
    </p>
    <p>
      (5)如果我们不再为您提供产品和服务。
    </p>
    <p class="fw-600 color-606266">
      当您从我们的服务中删除信息后，我们可能不会立即备份系统中删除相应的信息，但会在备份更新时删除这些信息。
    </p>
    <p>
      4.改变您授权同意的范围
    </p>
    <p>
      您可以通过提交请求，撤销授权我们收集、使用和/或披露我们掌握或控制的您的个人信息的许可。您可以通过第十四节列明的联系方式联系我们进行相关操作。我们将会在您做出请求后的合理时间内处理您的请求，并且会根据您的请求，在此后不再收集、使用和/或披露您的个人信息。<span class="fw-600 color-606266">请注意，由于每个业务场景需要一些基本的个人信息才能得以完成，您撤销同意可能导致部分易理货服务不可用。</span>
    </p>
    <p class="fw-600 color-606266">
      当您撤回同意后，我们将不再处理相应的个人信息。但您撤回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。
    </p>
    <p>
      5.注销账户
    </p>
    <p>
      您可以通过提交请求来申请注销您的易理货账户。 <span class="fw-600 color-606266">我们不会设置不合理的条件来阻止您注销账户，但如果注销账户将会影响您或他人的合法权益，例如您有待接收的货物、已付费而未到期的服务，则我们会结合您对易理货服务的使用情况来判断并告知是否支持您的注销请求。</span>
    </p>
    <p>
      注销账户会影响您正常使用所有相关应用和服务。 <span class="fw-600 color-606266">我们在此善意地提醒您，您注销账户的行为会给您后续使用易理货服务带来诸多不便，且注销易理货账户后，我们将停止为您提供服务，您的个人信息会保持不可被检索、访问的状态，或被匿名化处理，法律法规另有规定的除外。</span> 如您通过第三方账号快捷登录的，您需要联系该第三方处理注销账号事宜。
    </p>
    <p>
      如果您已经深思熟虑，确定放弃账户，并符合所有注销条件，您可以通过第十三节列明的联系方式联系我们进行相关操作。<span class="fw-600 color-606266">易理货账户一旦被注销将不可恢复，请您在操作之前自行备份易理货账户相关的所有信息和数据。因您申请注销账户对您造成的不利影响，易理货不承担责任。您注销易理货账户的行为不影响您在其他应用端对智能家电的控制和使用（如智家APP）。</span>
    </p>
    <p class="fw-600 color-606266">
      6.我们通常免费提供访问、更正、更新、删除服务，但是保留对您的数据访问请求收取合理费用的权利。
    </p>
    <p>
      九、我们如何处理未成年人的个人信息
    </p>
    <p>
      我们的服务主要面向成人。对于经父母或法定监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护未成年人所必要的情况下使用或公开披露合法收集到的未成年人的个人信息。
    </p>
    <p>
      如果我们发现自己在未事先获得可证实的父母或监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关数据。<span class="fw-600 color-606266">如果家长或监护人有理由相信未成年人未经他们事先同意而向易理货提交了个人信息，请联系我们以确保删除此类个人信息，并保证未成年人取消订阅任何适用的易理货服务。</span>
    </p>
    <p>
      对于可能涉及的不满14周岁的儿童个人信息，除上述措施外，我们进一步采取以下措施予以保障：
    </p>
    <p>
      对于收集到的儿童个人信息，我们除遵守本个人信息保护政策关于个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。我们会指定专人负责儿童个人信息保护事宜。同时，您作为监护人应当正确履行监护职责，保护儿童个人信息安全。
    </p>
    <p>
      十、您的个人信息的存储地点
    </p>
    <p>
      我们在中华人民共和国境内收集和产生的个人信息，将存储在中华人民共和国境内，将不会出境。
    </p>
    <p>
      十一、本个人信息保护政策不适用的范围
    </p>
    <p class="fw-600 color-606266">
      我们的个人信息保护政策不适用于第三方提供的产品和服务。易理货产品和服务可能包括第三方的产品和服务，以及第三方网站的链接。当您使用这些产品或服务时，也可能收集您的信息。因此，我们强烈建议您花时间阅读该第三方的个人信息保护政策，就像阅读我们的政策一样。我们不对第三方如何使用他们向您收集的个人信息负责，也不能控制其使用。我们的个人信息保护政策不适用通过我们的服务链接的其他网站。
    </p>
    <p>
      当您使用这些特定产品时（仅为举例说明，并非穷尽式列举），适用于以下第三方条款和个人信息保护政策： 1.支付：使用支付宝、微信支付或其他第三方支付服务完成和支付订单时，您将同意第三方支付服务供应商的个人信息保护政策适用于您在其网站上提供的信息。
    </p>
    <p>
      2.社交媒体（功能）：我们的网站包含一些社交媒体功能，例如“分享”按钮。社交媒体功能是由第三方提供的，您与这些功能的互动均受该第三方产品的个人信息保护政策的制约。
    </p>
    <p>
      3.快捷登录：您可以使用第三方账号来登录我们的网站或应用。这些服务将验证您的身份，并让您选择是否与我们分享某些个人信息（例如您的姓名和电子邮件地址），以预先填写我们的用户注册表单或绑定您已有的易理货账户。您使用的这些第三方账号受到该第三方相应个人信息保护政策的制约。
    </p>
    <p>
      4.使用第三方功能：您可能通过易理货提供的应用端来使用第三方服务，例如打开、访问、浏览、检索或注册登录第三方劳务电子签约平台。您在使用第三方功能时需要遵守第三方的服务条款和个人信息保护政策。
    </p>
    <p>
      十二、本个人信息保护政策如何更新
    </p>
    <p>
      <span class="fw-600 color-606266">我们的个人信息保护政策可能变更。未经您明确同意，我们不会削减您按照本个人信息保护政策所应享有的权利。我们会在本页面上发布对本个人信息保护政策所做的任何变更。</span> 对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明个人信息保护政策的具体变更内容）。本政策所指的重大变更包括但不限于：
    </p>
    <p>
      1.我们的服务模式（如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式）发生重大变化；
    </p>
    <p>
      2.我们在所有权结构发生重大变化（如业务调整、破产并购引起的所有者变更）；
    </p>
    <p>
      3.个人信息公开披露的主要对象发生变化；
    </p>
    <p>
      4.您参与个人信息处理方面的权利及其行使方式发生重大变化；
    </p>
    <p>
      5.我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；
    </p>
    <p>
      6.个人信息安全影响评估报告表明存在高风险时。
    </p>
    <p>
      十三、联系我们
    </p>
    <p>
      1.联系方式
    </p>
    <p>
      如果您对本个人信息保护政策有任何疑问、意见或建议，通过以下方式与我们处理个人信息保护事宜的部门联系：
    </p>
    <p>
      电子邮件：suncheng@jushanghui.com
    </p>
    <p>
      电话：16653212764
    </p>
    <p>
      2.响应您的请求
    </p>
    <p>
      为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。一般情况下，我们将在15个工作日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。
    </p>
    <p>
      我们可能会先要求您验证自己的身份，然后再处理您的请求。我们将在15个工作日内做出答复。如您不满意，还可以通过本节列明的联系方式联系我们进行投诉。
    </p>
    <p>
      对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定的成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。
    </p>
    <p>
      在以下情形中，按照法律法规要求，我们将无法响应您的请求：
    </p>
    <p>
      (1)与国家安全、国防安全有关的，或者违反政府部门的强制性要求；
    </p>
    <p>
      (2)与公共安全、公共卫生、重大公共利益有关的；
    </p>
    <p>
      (3)与犯罪侦查、起诉和审判等有关的；
    </p>
    <p>
      (4)有充分证据表明您存在主观恶意或滥用权利的；
    </p>
    <p>
      (5)响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。
    </p>
    <p>
      (6)涉及商业秘密的。
    </p>
    <p class="mt-20">
      <span class="fw-600 color-606266">提示：</span> 基于各国和不同司法管辖区法律和监管要求等方面的差别，如您在其他司法管辖区使用我们的服务时发现当地语言版本的个人信息保护政策，<span class="fw-600 color-606266">与本个人信息保护政策有所不同，</span> 请以您看到的当地语言版本为准。如有与本个人信息保护政策或您的个人信息使用相关的事项，请联系您所在地点特定的法律声明页面所载列的联系方式。
    </p>
    <p>
      发布于：2022年8月12日
    </p>
    <p>
      <span class="mr-20">©</span> 青岛巨商汇网络科技有限公司版权所有，保留一切权利。
    </p>
  </div>
</template>

<script>
export default {
  data () {
    return {
      background: this.$route.query.background !== '0'
    }
  },
  methods: {
    toLink (url) {
      window.open(url)
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  font-size: 14px;
  background-color: #ffffff;
  padding: 10px;
}

.fs-18 {
  font-size: 18px;
}

p {
  text-indent: 25px;
  margin: 0;
}

.center {
  text-align: center !important;
}
</style>
